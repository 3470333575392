.title {
    margin-top: 0;
}

.riddle-description {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.riddle-block {
    border-radius: 10px;
    background-color: rgb(20, 20, 20);
    padding: 2.5%;
    display: inline-block;
}

.riddle-paragraph {
    margin-bottom: 0;
}

.riddle-line {
    margin-top: 0;
    margin-bottom: 0;
}

@media only screen and (max-device-width: 480px) {
    .riddle-description {
        width: 100%;
    }
}
